import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const contact = {
  // sub_title: "STR_HAVE_A_QUERY",
  title: "STR_CONTACT_US",
  title_description: "STR_PLEASE_SUBMIT",
  mail: [
    {
      title: "STR_FOR_QUERIES",
      icon: faEnvelope,
      name: "marketing@skilrock.com",
      link: "mailto:marketing@skilrock.com",
    },
    {
      title: "STR_SEEKING_EMPLOYMENT",
      icon: faEnvelope,
      name: "career@skilrock.com",
      link: "mailto:career@skilrock.com",
    },
  ],
  contact: [
    {
      title: "STR_OFFICE_PHONE",
      icon: faPhone,
      name: "+91 124 4231900",
      link: "tel:+911244231900",
    },
  ],
  address: [
    {
      title: "India",
      title_part: "Head Office(Postal Address)",
      address_line1: "16th Floor, Building no 5, Tower A, DLF Cyber City,",
      address_line2: "Phase-III, Gurugram-122002, Haryana, India",
    },
    {
      title: "Africa",
      title_part: "",
      address_line1:
        "14a Anuoluwapo street off isheri osun road (olomowewe junction)",
      address_line2: "Ikotun- ijegun, Lagos, Nigeria",
    },
    {
      title: "Europe",
      title_part: "",
      address_line1: "1680, Todor Kableshkov 69, Sofia,",
      address_line2: "Bulgária",
    },
    {
      title: "Latin America",
      title_part: "",
      address_line1: "Av. Francisco de Paula Q. Ribeiro, 342 - ap 12A,",
      address_line2: "São Paulo, SP 04330-020 Brasil",
    },
    {
      title: "North America",
      title_part: "",
      address_line1: "7429 197 St, Langley-Vancouver,",
      address_line2: "V2Y3R6, Canada",
    },
  ],
};

const contactForm = {
  intrested_in: [
    {
      value: "LS",
      name: "Lottery Solutions",
    },
    {
      value: "IS",
      name: "iGaming Solutions",
    },
    {
      value: "JB",
      name: "Jobs",
    },
    {
      value: "PP",
      name: "Partnership",
    },
    {
      value: "OT",
      name: "Others",
    },
  ],
};

export { contact,contactForm };
