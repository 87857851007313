"use client";
import Link from "next/link";
import Image from "next/image";
import { footer as footerData } from "@/data/footer";
import socials from "@/data/social";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
export default function Footer({ params }) {
  const translated = useLanguageTranslations(params.locale, "footer");
  return (
    <>
      {translated && (
        <div className="section footer-section">
          <Image
            className="footer-section-image"
            src={footerData.footerBG}
            alt="Background-image"
            priority
          />
          <div className="container">
            <div className="footer-widget-wrap footer-widget-wrap-2">
              <div className="row">
                <div className="col-lg-3 col-12 col-md-3">
                  <div className="footer-widget-about">
                    <Link className="footer-logo" href={`/${params.locale}`}>
                      <Image src={footerData.logo} alt="logo" loading="lazy" />
                    </Link>
                    <div className="widget-info">
                      <ul className="link">
                        {footerData.contact.map((data, index) => {
                          return (
                            <li key={index}>
                              <div className="info-icon">
                                <i className={data.icon}></i>
                              </div>
                              <div className="info-text">
                                <span>
                                  <Link
                                    href={data.link}
                                    aria-label="social-media"
                                  >
                                    {data.name}
                                  </Link>
                                </span>
                              </div>
                            </li>
                          );
                        })}
                        {footerData.mail.map((data, index) => {
                          return (
                            <li key={index}>
                              <div className="info-icon">
                                <FontAwesomeIcon
                                  icon={data.icon}
                                  className="info-icon"
                                />
                              </div>
                              <div className="info-text">
                                <span>
                                  <Link
                                    href={data.link}
                                    aria-label="mail-contact"
                                  >
                                    {data.name}
                                  </Link>
                                </span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                {footerData.widgets.map((widget, indexWidget) => {
                  return (
                    <div className="col-lg-2 col-6 col-md-2" key={indexWidget}>
                      <div className="footer-widget">
                        <h3 className="footer-widget-title">
                          {translated(widget.title)}
                        </h3>
                        <div className="widget-link">
                          <ul className="link">
                            {widget.menus.map((menudata, index) => {
                              return (
                                <li key={index}>
                                  {menudata.link.startsWith("http") ? (
                                    <a
                                      href={menudata.link}
                                      aria-label="menu-links"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {translated(menudata.name)}
                                    </a>
                                  ) : (
                                    <Link
                                      href={`/${params.locale}/${menudata.link}`}
                                      aria-label="menu-links"
                                    >
                                      {translated(menudata.name)}
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {footerData.images.map((imagedata, index) => {
                  return (
                    <div key={index} className={imagedata.class}>
                      {imagedata.link && imagedata.link !== "" ? (
                        <Link
                          className="popup-video"
                          href={imagedata.link}
                          target="_blank"
                          aria-label="video"
                        >
                          <Image
                            src={imagedata.image}
                            alt={imagedata.alt}
                            loading="lazy"
                          />
                        </Link>
                      ) : (
                        <Image
                          className="winner-award"
                          src={imagedata.image}
                          alt={imagedata.alt}
                          loading="lazy"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="footer-copyright-area">
            <div className="container">
              <div className="footer-copyright-wrap">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="copyright-text">
                      <p>
                        © {translated(footerData.copyrightText)}
                        <br />
                        <small>
                          {translated(footerData.copyrightDescription)}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="copyright-social">
                      <ul className="social">
                        {socials.map((data, index) => {
                          return (
                            <li key={index}>
                              <Link
                                href={data.link}
                                target="_blank"
                                aria-label="social"
                              >
                                <FontAwesomeIcon icon={data.icon} />
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
