import React, { useState } from "react";
import Link from "next/link";
import { useTranslations } from "next-intl";
import Menus from "@/data/menu";
import Image from "next/image";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";

export const MobileMenu = ({ isOpen, toggleMenu, params }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const translated = useLanguageTranslations(params.locale, "navigation");
  const handleMenuClick = (index) => {
    setActiveMenu(index === activeMenu ? null : index);
  };
  if(!translated){
    return <Loader/>
  }
  return (
    <>
      <div className={`offcanvas-backdrop fade ${isOpen ? "show" : ""}`}></div>
      <div
        className={`offcanvas offcanvas-start ${isOpen ? "show" : "hide"}`}
        id="offcanvasExample"
        style={{ visibility: "visible" }}
        aria-modal="true"
        role="dialog"
        aria-label="offcanvas"
      >
        <div className="offcanvas-header justify-content-between">
          <div className="offcanvas-logo">
            <Link href="/" aria-label="offcanvas-logo">
              <Image src="/assets/images/logo/logo-white.png" alt="" width={150} height={50}/>
            </Link>
          </div>
          <button
            type="button"
            onClick={toggleMenu}
            className="close-btn"
            data-bs-dismiss="offcanvas"
            aria-label="Close menu"
          >
            <i className="flaticon-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="offcanvas-menu">
            <ul className="main-menu">
              {Menus.map((menu, index) => (
                <li
                  key={index}
                  className={`menu-item ${
                    activeMenu === index ? "active" : ""
                  }`}
                >
                  <Link
                    href={`/${params.locale}${menu.link ?? "#"}`}
                    onClick={(e) => {
                      if (menu.subMenus.length > 0) {
                        e.preventDefault();
                        handleMenuClick(index);
                      }
                    }}
                  >
                    {translated(menu.name)}
                  </Link>
                  {menu.subMenus.length > 0 && (
                    <>
                      <span
                        className="menu-icon"
                        onClick={() => handleMenuClick(index)}
                      ></span>
                      <span
                        className="menu-expand"
                        onClick={() => handleMenuClick(index)}
                      ></span>
                      <ul
                        className={`mobile-sub-menu ${
                          activeMenu === index ? "open" : ""
                        }`}
                      >
                        {menu.subMenus.map((subMenu, subIndex) => (
                          <li
                            key={subIndex}
                            className={subMenu.isActive ? "active-menu" : ""}
                          >
                            {subMenu.external ? (
                              <Link
                                href={`/${params.locale}${subMenu.link ?? "#"}`}
                                rel="nofollow"
                                target="_blank"
                              >
                                {translated(subMenu.name)}
                              </Link>
                            ) : (
                              <Link 
                              href={`/${params.locale}${subMenu.link ?? "#"}`}
                              >
                                {translated(subMenu.name)}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
